import React from 'react'
import Layout from '../../layout';
import StatisticComponent from '../../components/statistic';


const StatisticPage = () => {
  return (
    <>   
  
    <Layout>
        <StatisticComponent/>
    </Layout>
    </>
  )
}

export default StatisticPage;
