

import ComingSoon from '../../layout/coming-soon';
import style from './sheet.module.scss';

const TimeSheetComponent = () => {


  return (
    <>
      <ComingSoon />
    
    
    </>
  );
};

export default TimeSheetComponent;
