import "./style.css";
import PricingTable from "../../pricing/dark-pricing-table";


const SmartInvestment = () => {
  return (
    <div className="smart-investment-wrapper paddingSpaceTop">
     <PricingTable />
    </div>
  );
};

export default SmartInvestment;
