const Images = {
  blogPageImgthird: require("./blogPage-img-3.png"),
  blogPageImgtwo: require("./blogPage-img-2.png"),
  blogPageImgone: require("./blogPage-img-1.png"),
  blogPageImg4: require("./blogPage-img-4.png"),
  blogPageImg5: require("./blogPage-img-5.png"),
  blogPageImg6: require("./blogPage-img-6.png"),
  blogPageImg7: require("./blogPage-img-7.png"),
  blogPageImg8: require("./blogPage-img-8.png"),
  blogPageImg9: require("./blogPage-img-9.png"),
  blogPageImg10: require("./blogPage-img-10.png"),
  blogPageImg11: require("./blogPage-img-11.png"),
  blogPageImg12: require("./blogPage-img-12.png"),
  blogImgDog: require("./blog-img-dog.png"),
  blogImgempty: require("./emptyimage.png"),
  CustomerStoriesImage: require("./customersoriesCamera.png"),
 
  bloglistIcon: require("../icons/checkcircle.png"),
  rectangleImage:require("./Rectangle-4468.svg").default,
  manImage:require("./man-image.svg").default
};
export default Images;
