import Layout from "../../layout";
import TOS from "../../components/tos";
const TOSPage = () => {
  return (
    <Layout>
      <TOS />
    </Layout>
  );
};
export default TOSPage;
