

import ComingSoon from '../../layout/coming-soon';
import style from './task.module.scss';

const TaskManagementComponent = () => {


  return (
    <>
  <ComingSoon />
    
    </>
  );
};

export default TaskManagementComponent;
