

import ComingSoon from '../../layout/coming-soon';
// import style from './security.module.scss';

const SecurityComponent = () => {


  return (
    <>
      <ComingSoon />
    
    
    </>
  );
};

export default SecurityComponent;
