

import ComingSoon from '../../layout/coming-soon';
// import style from './statistic.module.scss';

const StatisticComponent = () => {


  return (
    <>
      <ComingSoon />
    </>
  );
};

export default StatisticComponent;
