

import ComingSoon from '../../layout/coming-soon';
// import style from './internal.module.scss';

const InternalChatComponent = () => {


  return (
    <>
     <ComingSoon />
    
    
    </>
  );
};

export default InternalChatComponent;
