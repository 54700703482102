

import ComingSoon from '../../layout/coming-soon';
// import style from './job-approval.module.scss';
const JobApprovalComponent = () => {
  return (
    <>
    <ComingSoon />
    </>
  );
};
export default JobApprovalComponent;
