import ComingSoon from '../../layout/coming-soon';
// import style from './electronic.module.scss';

const ElectronicComponent = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default ElectronicComponent;
