

import ComingSoon from '../../layout/coming-soon';
// import style from './automotive.module.scss';

const AutomotiveComponent = () => {


  return (
    <>
      <ComingSoon />
     
    
    
    </>
  );
};

export default AutomotiveComponent;
