import Layout from "../../layout";
import React from "react";
import CustomerStories from "../../components/customer-stories";
const CustomerStoriesPage = () => {


  return (
    <Layout>
     <CustomerStories />
    </Layout>
  );
};
export default CustomerStoriesPage;