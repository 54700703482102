

import ComingSoon from '../../layout/coming-soon';
// import style from './construction.module.scss';

const ConstructionComponent = () => {


  return (
    <>
      <ComingSoon />
     
    
    
    </>
  );
};

export default ConstructionComponent;
