

import ComingSoon from '../../layout/coming-soon';
// import style from './delete.module.scss';

const DeleteRequestComponent = () => {


  return (
    <>
    <ComingSoon />
    
    </>
  );
};

export default DeleteRequestComponent;
