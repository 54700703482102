import MemateWiki from "./memate-wiki";
const MemateWikiComponent = () => {

  return (
     <MemateWiki />
     
  );
};

export default MemateWikiComponent;
