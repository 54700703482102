

import style from './stories.module.scss';

const NewsStoriesComponent = () => {
  return (
    <>
      <div className={`kb-main-section ${style.mainHeadinstyle} ${style.comingSoon}`}>
      <h2>News Stories</h2>
     <h1> Coming <br></br>Soon</h1>   
      </div>
    </>
  );
};

export default NewsStoriesComponent;
