

import ComingSoon from '../../layout/coming-soon';
import style from './watch.module.scss';

const WatchDemoComponent = () => {


  return (
    <>
    <ComingSoon />
    
    
    </>
  );
};

export default WatchDemoComponent;
