const menuImages={
    featureImage:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/feature-selected-image.svg",
    featureImageUnselected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/feature-unselected-image.svg",
    industrySelected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/industry-selected.svg",
    industryUnSelected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/industry-unselected-image.svg",
    newStorySelected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/news-stories-selected.svg",
    newStoryUnSelected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/new-stories-unselected.svg",
    aboutSelected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/about-selected.svg",
    aboutUnselected:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/about-unselected.svg",
    unselectedLegal:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/unselected-legal.svg",
    unselectedResources:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/unselected-resources.svg",
    unselectedSales:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/unselected-feature-sales.svg',
    unselectedWatchDemo:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/unselected-watch-demo.svg',
    meMateCheck:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/memate-check.svg",
    RequestAdemo:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/request-a-demo.svg',
    clientManagement:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/client-management.svg",
    supplierManagement:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/supplier-management.svg",
    salesPipeline:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/sales-pipeline.svg",
    projectManagement:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/project-management.svg",
    jobApproval: "https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/job-approval.svg",
    MultiLocation: "https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/multi-location.svg",
    Calculators: "https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/calculators.svg",
    internalChat:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/internal-chat.svg",
    timeSheet:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/timsheet.svg",
    news:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/news.svg",
    taskManagement:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/task-management.svg",
    reports:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/reports.svg',
    profitAndBudget:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/profitability-budgetinh.svg",
    emplyManage:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/empma.svg',
    contractor:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/contractor.svg',
    communication:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/communication.svg",
    communicationIcon:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/communication-icon.svg",
    schduling:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/schduleing.svg",
    invoicing:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/invoiving.svg",
    statistics:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/statistics.svg',
    jobSchdule:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/job-schule.svg',
    jobHouly:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/job-hourly.svg',
    calendar:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/calendar.svg',
    creativeAgency:'https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/creative-agencies.svg',
    electronic:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/electronic.svg",
    photoVideoAgency:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/photo-video-agency.svg",
    automotive:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/automotive.svg",
    startUps:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/startups.svg",
    construction:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/construction.svg",
    selectedLegal:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/selected-legal.svg",
    selectedResources:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/selected-ressources.svg",
    featureIcon:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/featureIcon.svg",
    industriesIcon:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/industriesIcon.svg",
    newsIconMobile:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/newsIconMobile.svg",
    aboutMobileIcon:"https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/aboutMobileIcon.svg",
}

export default menuImages;