// import style from './calculators.module.scss';
import React from "react";
import ComingSoon from "../../layout/coming-soon";
const CalculatorsComponent = () => {
  return (
    <>
 <ComingSoon />
    </>
  );
};

export default CalculatorsComponent;
