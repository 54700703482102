

import ComingSoon from '../../layout/coming-soon';
// import style from './legal.module.scss';

const LegalComponent = () => {


  return (
    <>
    <ComingSoon />
    
    
    </>
  );
};

export default LegalComponent;
