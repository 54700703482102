

import ComingSoon from '../../layout/coming-soon';
import style from './scheduling.module.scss';

const SchedulingComponent = () => {


  return (
    <>
    <ComingSoon />
    
    
    </>
  );
};

export default SchedulingComponent;
