import ComingSoon from '../../layout/coming-soon';
// import style from './photo.module.scss';

const PhotoComponent = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default PhotoComponent;
