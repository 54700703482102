

import ComingSoon from '../../layout/coming-soon';
// import style from './features.module.scss';

const FeaturesNewsComponent = () => {


  return (
    <>
   <ComingSoon />
    
    
    </>
  );
};

export default FeaturesNewsComponent;
