import ComingSoon from '../../layout/coming-soon';
import style from './location.module.scss';

const MultiLocationComponent = () => {
  return (
    <>
     <ComingSoon />
    </>
  );
};

export default MultiLocationComponent;
