import ComingSoon from '../../layout/coming-soon';
// import style from './calendar.module.scss';

const CalendarComponent = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default CalendarComponent;
